import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 105px 16px;

  .title{
    font-size: 20px;
    color: #FFF;
    width: 100%;
    margin-left: 5px;
  }

  .cards{
    width: 100%;
    display:flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 25px; //19
    margin-bottom: 50px;

    > div{
      display: flex;
      align-items: center;
      height: 78px;
      border-radius: 12px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

      div{
        flex-direction: column;
        padding-left: 13px;

        h3{
          font-weight: 500;
        }

        span{
          color: #797979;
          font-size: 14px;
        }
      }
    }
  }
`;
