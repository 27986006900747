import React from 'react';
import { useHistory } from 'react-router-dom';
import { CarouselProvider, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import 'pure-react-carousel/dist/react-carousel.es.css';
import { Container, Image } from './styles';
import isMobile from '../../utils/isMobile';

function Carousel({ slides }) {
  const hist = useHistory();

  return (
    <Container>
      <CarouselProvider
        // naturalSlideWidth={300}
        // naturalSlideHeight={155}
        naturalSlideWidth={'300'} 
        naturalSlideHeight={!isMobile() ? '60' : '155'}
       
        totalSlides={slides.length}
        infinite={true}
        isPlaying={true}
        interval={3000}>
        <Slider>
          {slides.map(item => (
            <Slide key={item.id} index={0} style={{ backgroundImage: `${item.media_path}/${item.photo}` }}>
              { item.link.charAt(0) !== "h" ?
                <Image onClick={() => {
                  hist.push(item.link)
                }} src={`${item.media_path}/${item.photo}`}></Image>
                :
                <a href={item.link}>
                  <Image src={`${item.media_path}/${item.photo}`}></Image>
                </a>
              }
              {/* <img src={`${item.media_path}/${item.photo}`} alt={item.name} width={327} height={155} /> */}
            </Slide>
          ))}
        </Slider>
        <ButtonBack><MdKeyboardArrowLeft color="#F8F8F8" size={24} /></ButtonBack>
        <ButtonNext><MdKeyboardArrowRight color="#F8F8F8" size={24} /></ButtonNext>
      </CarouselProvider>
    </Container >
  );
}

export default Carousel;