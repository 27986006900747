import React from 'react';

import { Container } from './styles';

function Input({ id, label, type, placeholder, icon, register, validation, defaultValue, onKeyUp }) {

  return (
    <Container>
      <label htmlFor={id}>{ label }</label>
      <div>
        {icon}
        <input {...register(id, { ...validation })} id={id} type={type} placeholder={placeholder} defaultValue={defaultValue} onKeyUp={onKeyUp} />
      </div>
    </Container>
  );
}

export default Input;