import styled from 'styled-components';

export const Container = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
    background-color: #FFF;
    border: 2px solid #E5E5E5;
  }

  .container input:checked ~ .checkmark {
    background-color: #02DAC3;
  }
`;
