import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MdMenu, MdInsertDriveFile, MdLocationOn, MdPeople, MdExitToApp, MdAccountBalance } from 'react-icons/md';
import { HiOutlineMail } from 'react-icons/hi';
import { IoPersonCircleSharp, IoLogoYoutube } from 'react-icons/io5';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';

import { Wrapper, Background, Container, Header, Body, Footer, UserImage } from './styles';
import AuthContext from '../../contexts/auth';

import * as auth from '../../services/auth.js';

function DrawerMenu({ isOpened, closeCallback }) {
  const hist = useHistory();
  const { user } = useContext(AuthContext);

  function logout() {
    auth.logout();
    hist.push('/login');
  }

  return (
    <Wrapper>
      <CSSTransition in={isOpened} timeout={300} classNames="background" unmountOnExit>
        <Background isOpened={isOpened} onClick={closeCallback} />
      </CSSTransition>
      <CSSTransition in={isOpened} timeout={300} classNames="menu" unmountOnExit>
        <Container isOpened={isOpened}>
          <Header>
            <MdMenu size="30px" onClick={closeCallback} />
            <div>
              <UserImage src={`${user.media_path}/${user.photo}`} /> {/* `${user.media_path}/${user.photo}` */}
              <h2>{user.name.slice(0, 15) + (user.name.length > 15 ? "..." : "")}</h2>
            </div>
          </Header>
          <Body>
            <ul>
              <li onClick={() => hist.push("/edit_profile")}>
                <IoPersonCircleSharp size={20} color="#2B2B2B" />
                <span>Editar Cadastro</span>
              </li>
              <li onClick={() => hist.push("/")}>
                <MdInsertDriveFile size={20} color="#2B2B2B" />
                <span>Serviços</span>
              </li>
              <li onClick={() => hist.push("/contact_us")}>
                <HiOutlineMail size={20} color="#2B2B2B" />
                <span>Fale Conosco</span>               
              </li>
              <li onClick={() => hist.push("/address")}>
                <MdLocationOn size={20} color="#2B2B2B" />
                <span>Unidades</span>                
              </li>
              <li onClick={() => hist.push("/indicacao")}>
                <MdPeople size={20} color="#2B2B2B" />
                <span>Indicação</span>                
              </li>
              <li onClick={() =>{
                  window.open("https://meu.inss.gov.br/#/login","_blank");
              }}>
                <MdAccountBalance size={20} color="#2B2B2B" />
                <span>Meu INSS</span>                
              </li>
            
              {/* <a href="http://salgadojunior.com.br/indique">
                <li>
                  <MdPeople size={20} color="#2B2B2B" />
                Indicação
              </li>
              </a> */}
              <li onClick={logout}>
                <MdExitToApp size={20} color="#2B2B2B" />
                <span>Sair</span>                
              </li>
            </ul>
          </Body>
          <Footer>
            <div className="terms">
              <a href="https://salgadojunior.com.br/politicaprivacidadesalgado.htm" target="_blank" rel="noreferrer">Termos & Política de Privacidade</a>
              <span>Versão 1.0.0</span>
            </div>
            <div className="social">
              <span>Siga-nos</span>
              <div>
                <a href="https://www.facebook.com/SalgadoJuniorAdvocacia/">
                  <FaFacebook size={20} />
                </a>
                <a href="https://www.linkedin.com/company/salgadojradv/?originalSubdomain=br/">
                  <FaLinkedin size={20} />
                </a>
                <a href="https://www.instagram.com/salgadojunioradv/">
                  <FaInstagram size={20} />
                </a>
                <a href="https://www.youtube.com/channel/UCXPI6nIUnX0-U-VvLeh7ocQ">
                  <IoLogoYoutube size={20} />
                </a>
              </div>
              <a href="http://salgadojunior.com.br">www.salgadojunior.com.br</a>
            </div>
          </Footer>
        </Container>
      </CSSTransition>
    </Wrapper>
  );
}

export default DrawerMenu;