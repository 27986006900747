import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import AuthContext, { AuthProvider } from './contexts/auth';
import { SlidesProvider } from './contexts/slides';
import { AdressesProvider } from './contexts/adresses';
import { NotificationsProvider } from './contexts/notifications';
import ServicesContext, { ServicesProvider } from './contexts/services';

import Home from './pages/Home';
import Indicacao from './pages/Inidicacao';
import Register from './pages/Register';
import Login from './pages/Login';
import EditProfile from './pages/EditProfile';
import Intro from './pages/Intro';
import RecoveryPassword from './pages/RecoveryPassword';
import Address from './pages/Address';
import ContactUs from './pages/ContactUs';
import UpDoc from './pages/UpDoc';
import Notifications from './pages/Notifications';
import Chat from './pages/Chat';
import PageCategory from './pages/PageServices/PageCategory';
import PageContent from './pages/PageServices/PageContent';

import Loading from './components/Loading';

import { isAuthenticated } from './services/auth';

function PrivateRoute({ component: Component, ...rest }) {
  const { loading } = useContext(AuthContext);
  const { loading: servicesLoading } = useContext(ServicesContext);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          rest.path === '/' || rest.path.includes('/categorias') || rest.path.includes('/servicos') ? (
            (loading || servicesLoading) ?  <Loading background="#fff" /> : <Component {...props} />
          ) : (
            loading ?  <Loading background="#fff" /> : <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
}

function Routes() {
  return (
    <BrowserRouter basename="/app">
      <Switch>
        <Route path="/register" component={Register} />
        <Route path="/recovery_password" component={RecoveryPassword} />
        
        <AuthProvider>
          <Route path="/login" component={Login} />
          <NotificationsProvider>
            <ServicesProvider>
              <SlidesProvider>
                <AdressesProvider>
                  <PrivateRoute path="/" exact component={Home} />
                  <PrivateRoute path="/indicacao" exact component={Indicacao} />
                  <PrivateRoute path="/intro" component={Intro} />
                  <PrivateRoute path="/edit_profile" component={EditProfile} />
                  <PrivateRoute path="/address" component={Address} />
                  <PrivateRoute path="/contact_us" component={ContactUs} />
                  <PrivateRoute path="/upload_doc" component={UpDoc} />
                  <PrivateRoute path="/notifications" component={Notifications} />
                  <PrivateRoute path="/chat" component={Chat} />
                  <PrivateRoute path="/categorias/:id" component={PageCategory} />
                  <PrivateRoute path="/servicos/:categoria/:servico/:subservico?" component={PageContent} />
                </AdressesProvider>
              </SlidesProvider>
            </ServicesProvider>
          </NotificationsProvider>
        </AuthProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;