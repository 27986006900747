import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 10px;
    line-height: 12px;
    color: #002854;
    margin-top: 10px;
  }

  div {
    margin-top: 8px;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding-left: 14px;

    input {
      width: 95%;
      padding: 18px 12px;
      font-size: 13px;
      line-height: 15px;
      outline: none;
    }

    input::placeholder{
      color: #797979;
    }
  }
`;
