import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: #011C3A;
  border-radius: 12px;
  z-index: 0;

  .carousel {
    color: #F8F8F8;
    text-align: center;
    border-radius: 12px;
  }

  .sliderTray___-vHFQ {
    border-radius: 12px;
  }

  .slideInner___2mfX9 {
    border-radius: 12px;
  }

  .slideHorizontal___1NzNV {
    border-radius: 12px;
  }

  .horizontalSlider___281Ls {
    border-radius: 12px;
  }

  .buttonBack___1mlaL {
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    outline: none;
  }

  .buttonNext___2mOCa {
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    outline: none;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
`;