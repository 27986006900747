import styled from 'styled-components';

export const Container = styled.div`
  padding: 43px 18px;
  display:flex;
  flex-direction: column;

  img {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 132px;
    align-self: center;
  }

  .button-link{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
  }

`;

export const Error = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #D61F2A;
`;

export const Message = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #002854;
`;

export const Icon = styled.div`
  text-align: center;
`;

export const TextModal = styled.div`
  text-align: center;

  span{
    font-weight: 700;
    width: 200px;
  }

  .help-text{
    font-weight: 700;
    width: 250px;
    margin-top: 10px;
    color: #797979;
    font-size: 12px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;