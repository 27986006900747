import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { BsWhatsapp } from "react-icons/bs";
import { MdPhone, MdAccountCircle, MdMailOutline } from 'react-icons/md';

import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import DrawerMenu from '../../components/DrawerMenu';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';

import { Container, Error, Icon, TextModal, Buttons } from './styles';

import Card from '../../components/Card';
import TextInput from '../../components/TextInput';
import MaskedInput from '../../components/MaskedInput';
import Button from '../../components/Button';

import checkCircle from '../../assets/check-circle.gif';
import errorCircle from '../../assets/erro-circle.gif';
import api from '../../services/api';
import AuthContext from '../../contexts/auth';
import HistoryList from '../../components/Indicacao/HistoryList';
import {useIndicacao} from '../../contexts/indicacao';

function Indicacao() {
  const {getAllIndicacao} = useIndicacao();
  const [menuOpened, setMenuOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [msgModal, setMsgModal] = useState(''); 
  const [hasErro, setHasErro] = useState(false);

  const hist = useHistory();  
  const { user } = useContext(AuthContext);
  const { register, control, handleSubmit, formState: { errors } } = useForm();

  function onSubmit(data) {
    const form = new FormData();
    form.append('name',data.nome);
    form.append('email',data.email);
    form.append('telefone',data.telefone);
    form.append('celular',data.whatsapp);

    if(user && user.id){
      form.append('user_id',user.id);
    }

    //console.log('data: ',data);
    setIsLoading(true);
    api
    .post(`/indicacao`,form)
    .then(() => { 
      setConfirmation(true);
      setHasErro(false);
      getAllIndicacao();
      setMsgModal('Sua Indicação foi enviada com sucesso!');

    })
    .catch((error) => {
      setConfirmation(true);
      setHasErro(true);
      setMsgModal('Erro ao tentar enviar a indicacão Indicação');
      //console.log('Indicacao: ', error);
      //console.log('Indicacao: ', error.response.data);
    })
    .finally(() => {
      setIsLoading(false);      
    });
  }

  function closeModal() {
    setConfirmation(false);
    setHasErro(false);
    setMsgModal('');
  }

  return(
    <>
      {isLoading && (<Loading />)}
      {confirmation && (
        <Modal onClick={closeModal}>
          <Icon><img src={hasErro ? errorCircle : checkCircle} alt="Message modal" height={hasErro ? 150 : 48} /></Icon>
          <TextModal>
            <span>{msgModal}</span>
          </TextModal>
          {
            !hasErro ? (
              <Buttons>
                <Button text="Voltar para a Home" color={0} onClick={() => hist.push('/')} />
              </Buttons>
            ) : null
          }          
        </Modal>
      )}
      <Container>
        <Header title="Indicação" routeBack={true} menuCallback={() => setMenuOpened(true)} />
       
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              register={register}
              validation={{ required: true }}
              id="nome"
              label="*Nome Completo"
              placeholder="Digite o nome"
              icon={<MdAccountCircle size={18} color="#797979" />}              
            />
            {errors.nome && <Error>O campo nome não pode ser vazio!</Error>}
            <TextInput
              register={register}
              validation={{
                required: {
                  value: true,
                  message: 'O campo email não pode ser vazio!',
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: 'Digite um endereço de email válido!',
                },
              }}
              id="email"
              label="Email"
              placeholder="@"
              type="email"
              icon={<MdMailOutline size={18} color="#797979" />}
            />
            {errors.email && <Error>{errors.email?.message}</Error>}
            <MaskedInput
              control={control}
              validation={{ required: true }}
              id="telefone"
              label="*Telefone"
              placeholder="(00) 00000-0000"
              mask="(99) 9999-9999"
              type="tel"
              icon={<MdPhone size={18} color="#797979" />}              
            />
            <MaskedInput
              control={control}
              validation={{ required: true }}
              id="whatsapp"
              label="Whatsapp*"
              placeholder="(00) 00000-0000"
              mask="(99) 99999-9999"
              type="tel"
              icon={<BsWhatsapp size={18} color="#797979" />}              
            />
            {errors.whatsapp && <Error>O campo whatsapp não pode ser vazio!</Error>}
            <div style={{marginTop:"20px"}} className="button-edit">
              <Button text="Enviar" />
            </div>
          </form>
        
        </Card>
        <Card style={{marginTop:'2%', marginBottom: '5%'}}>
          <HistoryList />
        </Card>
      </Container>
      <Navbar />
      <DrawerMenu isOpened={menuOpened} closeCallback={() => setMenuOpened(false)} />
    </>
  );

}

export default Indicacao;