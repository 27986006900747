import React from 'react';

import { Container } from './styles';

function Checkbox({ id, onClick }) {
  return (
    <Container>
      {/* <input type="checkbox"/> */}
      <label className="container">
        <input type="checkbox" onClick={onClick}/>
        <span className="checkmark"></span>
      </label>
    </Container>
  );
}

export default Checkbox;