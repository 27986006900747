import React, { useState, useEffect, useContext } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';

import ServicesContext from '../../../contexts/services';

import Card from '../../../components/Card';
import Header from '../../../components/Header';
import Navbar from '../../../components/Navbar';

import { Container, Category } from './styles';

function PageCategory() {
  const { structured } = useContext(ServicesContext);
  const [service, setService] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    setService(structured[id]);
  }, [structured, id]);

  function toggleListItem(item) {
    setExpandedItems(expandedItems.includes(item)
      ? expandedItems.filter(listItem => listItem !== item)
      : [...expandedItems, item]);
  }

  function formatTitle(title) {
    const arr = title.split(' ');
    arr.shift();
    return arr.join(' ');
  }

  return (
    <>
      <Container>
        <Header title={service ? formatTitle(service.title) : ''} route="/" />
        <h2 className="title">Escolha um serviço dentro da categoria:</h2>
        <Card style={{ padding: '22px 14px' }}>
          <div className="card">
            {service && Object.entries(service.children).map(item => (
              <Category style={{cursor:"pointer"}} key={item[0]}>
                <div className="category-title" onClick={
                  Object.entries(item[1].children).length > 0 
                    ? () => toggleListItem(item[0]) 
                    : () => history.push(`/servicos/${id}/${item[0]}`)}>
                  <span style={{ flexGrow: '1' }}>{formatTitle(item[1].title)}</span>
                  {Object.entries(item[1].children).length > 0 &&
                    (expandedItems.includes(item[0])
                      ? <FaMinus color="#C59742" size={20} style={{ flexShrink: '0' }} />
                      : <FaPlus color="#C59742" size={20} style={{ flexShrink: '0' }} />)
                  }
                </div>
                {expandedItems.includes(item[0]) &&
                  Object.entries(item[1].children).map(itemService =>
                    <div key={itemService[0]} className="body" onClick={() => history.push(`/servicos/${id}/${item[0]}/${itemService[0]}`)}>
                      <span>{formatTitle(itemService[1].title)}</span><br />
                    </div>
                  )}
              </Category>
            ))}
          </div>
        </Card>
      </Container>
      <Navbar />
    </>
  );
}

export default PageCategory;