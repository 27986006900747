import React from 'react';

import { Container } from './styles';

function Button({ text, onClick, type, color, disable }) {
  return (
    <Container color={color}>
      {
        disable ? 
        <button disabled style={{backgroundColor: "#D3D3D3"}}>{text} </button>
        :
        <button type={type} onClick={onClick}>{text}</button>
      }
    </Container>
  );
}

export default Button;
