import styled from 'styled-components';

export const Container = styled.div`
  @media(min-width: 820px) {
    & {
      display: none;
    }
  }
  footer{
    height: 78px;
    background-color: #F8F8F8;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0px;
    gap: 25px;
    z-index: 4;
  }
`;
