import React, { useContext, useEffect } from 'react';
import { MdFileDownload } from 'react-icons/md';
import InfinityScroll from 'react-infinite-scroll-component';

import Card from '../../components/Card';
import Header from '../../components/Header';

import AuthContext from '../../contexts/auth';
import NotificationsContext from '../../contexts/notifications';

import api from '../../services/api';

import { Container, List, File } from './styles';

function Notifications() {
  const { user } = useContext(AuthContext);
  const { notifications, hasMore, next, setHasUnseen } = useContext(NotificationsContext);

  useEffect(() => {
    api.get('notificacao/send-view-all').then(() => {
      setHasUnseen(false);
    }).catch((error) => {
      console.error(error);
    });
  }, [setHasUnseen]);

  return (
    <>
      <Container>
        {/* <Header image={`${user.media_path}/${user.photo}`} title={`Olá ${user.name.substring(0, user.name.indexOf(' '))}!`} route="/" state={0} /> */}
        <Header image={`${user.media_path}/${user.photo}`} title="Voltar" routeBack={true} routeBackNotification={true}/>
        <h2 className="title">Notificações</h2>
        <List>
          <InfinityScroll
            dataLength={notifications.length}
            next={next}
            hasMore={hasMore()}
          >
            {notifications.map(notification => (
              <Card key={notification.id}>
                <div className="content">
                  <span className="title2">{notification.title}</span>
                  <span className="instructions">{notification.description}</span>
                  {notification.files.map(file => (
                    <File 
                      key={file.id}
                      href={`${file.media_path}/${file.name}`}
                    >
                      <span>{file.name}</span>
                      <MdFileDownload size={24}/>
                    </File>
                  ))}
                </div>
              </Card>
            ))}
          </InfinityScroll>
        </List>
      </Container>
    </>
  );
}

export default Notifications;