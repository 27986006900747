import styled from 'styled-components';

export const TableResposive = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

export const TableContainer = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;   
  
  >tbody tr:hover {
    cursor: pointer;
    border-top: 0.3px solid #ccc;
    border-left: 1px solid #cccc;
    box-shadow: 1px 1px 1px 1px #ccc;
    background-color: rgb(0 0 0 / 0%);
  }
`;

export const ThContainer = styled.th`
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
`;

export const TdContainer = styled.td`
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
`;