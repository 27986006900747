import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
  width: 100%;
  font-size: 24px;

  .link{
    text-decoration: none;

    .left{
      display: flex;
      align-items: center;
      
      .img-user{
        height: 50px;
        width: 50px;
        border-radius: 40px;
        background-color: #C59742;
      }

      h2{
        color: white;
        font-weight: bold;
        margin-left: 8px;
      }
    }
  }

  .right{
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
  }

  @media only screen and (max-width: 300px) {
    .link .left h2 {
      display: none;
    }
  }
`;

export const UserImage = styled.image`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  min-height: 50px;
  min-width: 50px;
  border-radius: 40px;
  background-color: #C59742;
`;

export const NotificationIndicator = styled.div`
  background-color: #FF2739;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 16px;
`;

export const Nav = styled.nav`
  @media(max-width: 820px) {
    & {
      display: none;
    }
  }
  & ul {
    cursor: pointer;
  };

  & ul label {
    cursor: pointer;
  };
  
  & ul li {
    display: inline-block;
    margin-right: 30px;
    
  };

  & ul li:last-child {
    margin-right: 0;
  };

  & ul li label{    
    font-size: 24px;
    font-weight: 600;
    display: block;
    text-decoration: none;
    position: relative;
    color: #fff;
  };

  & ul li label::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 10;
    width: 0%;
    background-color: #C59742;
    height: 2px;
    transition: 300ms all;
  };

  & ul li label:hover::after {
      width: 100%;
  }
`;


