import React, {useEffect} from 'react';
import {uuid} from 'uuidv4';
import {Pagination} from './styles';

const NavPagination = ({
  links,
  current_page,
  asyncPagination,
  isScrollBottom=false,
}) => {
  useEffect(() => {}, []);
  return (
    <nav>
      <Pagination className="pagination">
        {links.map((link, index) => {
          const isPrev = index === 0;
          const isNex = index === links.length - 1;
          let myclass = link.url ? 'page-item' : 'page-item disabled';
          // eslint-disable-next-line radix
          const id = parseInt(link.label);
          myclass = current_page === id ? `${myclass} active` : myclass;

          return (
            <li
              key={`${uuid()}`}
              onClick={() => {
                if (link.url) {
                  asyncPagination(link.url,()=>{
                    if(isScrollBottom){
                      setTimeout(()=>{                       
                        window.scrollTo({
                          top:document.body.scrollHeight,
                          behavior: 'smooth'
                        });
                      },200)
                      
                    }                   
                  });
                }
              }}
              className={myclass}>
              <button type="button" className="page-link">
                {isPrev ? 'Voltar' : isNex ? 'Avançar' : link.label}
              </button>
            </li>
          );
        })}
      </Pagination>
    </nav>
  );
};

export default NavPagination;
