import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

export const TopBar = styled.div`
  background: #E50150;
  padding: 42px 8px 0px 8px;
`;

export const Frame = styled.iframe`
  width: 100%;
  height: 100%;
`;