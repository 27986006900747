import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 105px 16px;

  .title{
    font-size: 20px;
    color: #FFF;
    width: 275px;
    margin-bottom: 17px;
    margin-left: 15px;
  }

  .card{
    /* max-height: 50vh; */
    overflow: auto;
  }

`;

export const Category = styled.div`
  border-bottom: 1px  solid #E5E5E5;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .category-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      color: #C59742;
      font-weight: bold;
    }
  }

  .body {
    /* height: 42px; */
    background: #F8F8F8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    margin-bottom: 8px;
    
    display: flex;
    align-items: center;
    padding: 12.5px;
    
    span{
      width: 100%;
      font-size: 14px;
    }
  }
`;
