import React from 'react';

import { Container } from './styles';

function RadioInput({ title, group, options, style, register, defaultValue }) {
  return (
    <Container style={style}>
      <span>{ title }</span>
      <div>
        {options && options.map(item => (
          <div key={item.value}>
            <input 
              {...register(group)}
              type="radio"
              id={item.value}
              name={group}
              value={item.value}
              defaultChecked={item.checked} 
            />
            <label htmlFor={item.value}>{item.label}</label>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default RadioInput;