import React, { Component } from 'react';
import { CommonLoading } from 'react-loadingg';

// import logo from '../../assets/logo.png'

export default class Loading extends Component {

  render() {
    return (
      <div style={{ zIndex:999, position: 'absolute', top: 0, left: 0 }}>
        <CommonLoading color="#002854" style={{ 
          position: 'fixed',
          marginTop: '50vh',
          marginLeft: '50vw',
          transform: 'translateX(-50%) translateY(-50%)'
        }} />
        {/* <img src={logo} height={110} style={{ position: 'fixed', marginTop: '50vh', marginLeft: '50vw', transform: 'translateX(-50%) translateY(-50%)' }} alt="caveira"/> */}
        <div style={{ 
          backgroundColor: this.props.background ? this.props.background : '#00000055',
          position: 'fixed', 
          height: '100vh', 
          width: '100vw', 
          zIndex: -1 
        }} />
      </div>
    )
  }
}