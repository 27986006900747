import { createContext, useContext, useEffect, useState } from "react";

import AuthContext from "./auth";

import api from "../services/api";
import { isAuthenticated } from "../services/auth";

const SlidesContext = createContext({
  slides: [],
  loading: true,
  refresh: () => {}
});

export const SlidesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadSlides();
  }, [user]);

  function loadSlides() {
    if (isAuthenticated()) {
      setLoading(true);
      api
        .get('/social-media')
        .then((res) => {
          const { data } = res.data.data;
          setSlides(data);
        })
        .catch((error) => {
          console.log('getBannerSlides: ', error);
          console.log('getBannerSlides: ', error.response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <SlidesContext.Provider value={{ slides, loading, refresh: loadSlides }}>
      {children}
    </SlidesContext.Provider>
  );
}

export default SlidesContext;