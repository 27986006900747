import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 105px 16px;

  .title{
    font-size: 20px;
    color: #FFF;
    width: 275px;
    margin-bottom: 17px;
    margin-left: 15px;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title2{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  div{
    color: #797979;
  }

  .doc{
    overflow: auto;
    max-height: 25vh;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button{
    font-weight: 400;
  }
`;
