import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom'; 
import { CSSTransition } from 'react-transition-group';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { Container, Slide, SlideController, SlideCursor } from './styles';

import logo from '../../assets/logo.png';
import AuthContext from '../../contexts/auth';
import Loading from '../../components/Loading';

function Intro() {
  const { updateFirstTime, loading } = useContext(AuthContext);
  const hist = useHistory();
  const slides = [Slide1, Slide2];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [changeSlide, setChangeSlide] = useState(false);

  function Slide1() {
    return (
      <Slide onClick={next}>
        <span className="size40">Bem-vindo ao</span>
        <span className="size26">app Salgado Junior</span>
      </Slide>
    );
  }

  function Slide2() {
    return (
      <Slide onClick={next}>
        <span className="size30">Aqui você pode acompanhar informações atualizadas sobre seu processo e solicitar novos serviços.</span>
      </Slide>
    );
  }

  function next() {
    if ((currentSlide + 1) < slides.length) {
      setCurrentSlide(currentSlide + 1);
      setChangeSlide(true);
    }
  }

  function setSlide(index) {
    setCurrentSlide(index);
    setChangeSlide(true);
  }

  return (
    <Container>
      {loading && (<Loading />)}
      <header>
        <img src={logo} alt="Logotipo Salgado Junior" height={82}/>
      </header>
      <section>
        <CSSTransition in={changeSlide} timeout={200} classNames="slider" onEnter={() => setChangeSlide(false)}>
          {slides[currentSlide]()}
        </CSSTransition>
        <SlideController>
          <div>
            {slides.map((item, index) => (
              <SlideCursor key={index} checked={currentSlide === index} onClick={() => setSlide(index)} />
            ))}
          </div>
          {currentSlide === (slides.length - 1) ? 
            <span onClick={() =>  {
              updateFirstTime(true,()=>{
                hist.push("/");
              });
             
            }} className="link">
              <span>Começar</span>
              <MdKeyboardArrowRight size={24}/>
            </span> : 
            <span className="link" onClick={next}>
              <span>Próximo</span>
              <MdKeyboardArrowRight size={24}/>
            </span>}
        </SlideController>
      </section>
      <footer>
        <a href="http://www.salgadojunior.com.br">www.salgadojunior.com.br</a>
      </footer>
    </Container>
  );
}

export default Intro;