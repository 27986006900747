import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Container } from './styles';

function MaskedInput({ 
  id,
  label,
  type,
  placeholder,
  icon,
  control,
  validation,
  defaultValue,
  mask
}) {

  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <div>
        {icon}
        <Controller
          name={id}
          control={control}
          rules={validation}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <InputMask mask={mask} value={value} onChange={onChange}>
              {(inputProps) => (
                <input
                  {...inputProps}
                  type={type}
                  placeholder={placeholder}
                  className="input"
                />
              )}
            </InputMask>
          )}
        />
      </div>
    </Container>
  );
}

export default MaskedInput;