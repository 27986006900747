import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdLock, MdMailOutline, MdPhone, MdAccountCircle } from 'react-icons/md';
import { useForm } from 'react-hook-form';

import Card from '../../components/Card';
import TextInput from '../../components/TextInput';
import MaskedInput from '../../components/MaskedInput';
import RadioInput from '../../components/RadioInput';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Loading from '../../components/Loading';

import api from '../../services/api';

import logo from '../../assets/logo.png'
import Modal from '../../components/Modal';
import checkCircle from '../../assets/check-circle.gif';

import { Container, Error, Icon, TextModal, Buttons } from './styles';

function Register() {
  const hist = useHistory();
  const { register, control, handleSubmit, formState: { errors } } = useForm();
  const [confirmation, setConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textError, setTextError] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  function onSubmit(data) {

    setIsLoading(true);

    const form = new FormData();
    form.append('name', data.nome);
    form.append('email', data.email);
    form.append('password', data.senha);
    form.append('password_confirmation', data.senha);
    form.append('is_customer', `${data.cliente === 'sim' ? 1 : 0}`);
    form.append('cpf', '000.000.000-00');
    form.append('telefone', data.telefone);
    form.append('email_inss', data.emailinss);
    form.append('password_inss', data.senhainss);

    api
      .post(`/users-app`, form)
      .then(() => {
        setConfirmation(true)
        localStorage.setItem('email_login', data.email);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setTextError("Email já está sendo usado!")
        }
      }).finally(() => {
        setIsLoading(false);
      });
  }

  function closeModal() {
    setConfirmation(false)
  }

  return (
    <>
      {isLoading && (<Loading />)}
      {confirmation && (
        <Modal onClick={closeModal}>
          <Icon><img src={checkCircle} alt="Finalizado com sucesso" height={48} /></Icon>
          <TextModal>
            <span>Cadastrado com sucesso!</span>
          </TextModal>
          <Buttons>
            <Button text="Voltar para o Login" color={0} onClick={() => hist.push('/login')} />
          </Buttons>
        </Modal>
      )}
      <Container>
        <img src={logo} alt="Logo App Salgado" />
        <h1>Cadastrar</h1>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              register={register}
              validation={{ required: true }}
              id="nome"
              label="*Nome Completo"
              // placeholder="Exemplo: Osmar de Osvaldo"
              icon={<MdAccountCircle size={18} color="#797979" />}
            />
            {errors.nome && <Error>O campo nome não pode ser vazio!</Error>}
            <TextInput
              register={register}
              validation={{
                required: {
                  value: true, message: 'O campo email não pode ser vazio!',
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: 'Digite um endereço de email válido!',
                },
              }}
              id="email"
              label="*Email / Login"
              // placeholder="@"
              type="email"
              onKeyUp={() => setTextError("")}
              icon={<MdMailOutline size={18} color="#797979"
              />}
            />
            {textError && <Error>{textError}</Error>}
            {errors.email && <Error>{errors.email?.message}</Error>}
            <MaskedInput
              control={control}
              validation={{
                required: {
                  value: true, message: 'O campo telefone não pode ser vazio!',
                },
                pattern: {
                  // value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: 'Digite um telefone válido!',
                },
              }}
              id="telefone"
              label="*Telefone / Login"
              // placeholder="(00) 0 0000-0000"
              mask="(99) 9 9999-9999"
              type="tel"
              icon={<MdPhone size={18} color="#797979" />}
            />
            {errors.telefone && <Error>O campo telefone não pode ser vazio!</Error>}
            <TextInput
              register={register}
              validation={{
                required: true, minLength: 6
              }}
              id="senha"
              label="*Senha"
              // placeholder="*********"
              type="password"
              icon={<MdLock size={18} color="#797979" />}
            />
            {errors.senha && errors.senha.type === "minLength" && <Error>Deve ter no mínimo 6 caracteres</Error>}
            {errors.senha && errors.senha.type === "required" && <Error>O campo senha não pode ser vazio!</Error>}
            <RadioInput
              register={register}
              title="*Cliente"
              group="cliente"
              options={[{ label: 'Sim', value: 'sim', checked: true }, { label: 'Não', value: 'nao' }]}
            />

            <TextInput
                register={register}                
                id="emailinss"
                label="Login INSS"
                placeholder="@"
                type="txt"
                icon={<MdMailOutline size={18} color="#797979" />}               
            />
            {errors.emailinss && <Error>{errors.email?.emailinss}</Error>}
            <TextInput
              register={register}
              id="senhainss"
              label="Senha INSS"
              placeholder="*********"
              type="txt"
              icon={<MdLock size={18} color="#797979" />}
            />
            {errors.senhainss && <Error>{errors.senhainss?.message}</Error>}
            <div className="checkbox">
              <Checkbox onClick={() => setDisableButton(!disableButton)}/><span>Estou de acordo com os <a href="https://salgadojunior.com.br/politicaprivacidadesalgado.htm" target="_blank" rel="noreferrer">Termos de Uso & Política de Privacidade</a> deste App</span>
            </div>
            <div className="button-register">
              <Button text="Cadastrar" disable={disableButton}/>
              <span onClick={() => hist.push("/login")} className="link">Já tenho um cadastro</span>
            </div>
          </form>
        </Card>
      </Container>
    </>
  );
}

export default Register;