import React, { useContext, useState } from 'react';

import AdressesContext from '../../contexts/adresses';

import Card from '../../components/Card';
import DrawerMenu from '../../components/DrawerMenu';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import Loading from '../../components/Loading';

import { Container } from './styles';

import marker from '../../assets/marker.png';

function Address() {
  const [menuOpened, setMenuOpened] = useState(false);
  const { adresses, loading } = useContext(AdressesContext);

  return (
    <>
      <Container>
        {loading && (<Loading />)}
        <Header title="Unidades" routeBack={true} />
        <div className="cards">
          {adresses.map(item =>
            <Card key={item.id}>
              <a href={item.link} rel="noreferrer" target="_blank">
                <img src={marker} alt="Marcador" />
                <div>
                  <h3>{item.title}</h3>
                  <span>{item.address}</span>
                </div>
              </a>
            </Card>
          )}
        </div>
      </Container>
      <Navbar />
      <DrawerMenu isOpened={menuOpened} closeCallback={() => setMenuOpened(false)} />
    </>
  );
}

export default Address;