import React from 'react';
import Card from '../Card';

import { MdClose } from 'react-icons/md'

import { Container, CloseModal, Body } from './styles';

function Modal({ children, onClick}) {
  return (
    <Container>
      <Card>
        <CloseModal>
          <MdClose onClick={onClick} color="#000E1D"/>
        </CloseModal>
        <Body>
          {children}
        </Body>
      </Card>
    </Container>
  );
}

export default Modal;