import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  position: fixed;

  >div{
    max-width: 350px;
  }

  @media(max-width: 340px){
    > div{
      max-width: 300px;
    }
  }
`;

export const Body = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
`;