import styled from 'styled-components';

export const Container = styled.div`
  button{
    width: 185.98px;
    height: 43.06px;
    left: 94.44px;
    top: 550.97px;

    background: ${props => props.color === 0 ? "#C59742" : "#002854"};
    border-radius: 70px;

    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
  }
`;