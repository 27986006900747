import { createContext, useState, useContext, useCallback } from 'react';
import {getToken} from '../services/auth';
import api, {apiAsync} from "../services/api";


const IndicacaoContext = createContext({});

const urlHistory = "indicacao-get-my-history";

export const IndicacaoProvider = ({ children }) => {
	const token = getToken();
	const [list, setList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [perPagePage, setPerPagePage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState([]);

	const getList = useCallback(() => list, [list]);

  const getIsLoading = useCallback(() => isLoading, [isLoading]);

  const getCurrentPage = useCallback(() => currentPage, [currentPage]);

  const getPagination = useCallback(() => pagination, [pagination]);

	const getAllIndicacao = useCallback(async () => {
    setIsLoading(true);
    api
      .get(`${urlHistory}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((res) => {
        setIsLoading(false);
        const {data, links, current_page, total, per_page} = res.data.data;
        setList(data);
        setCurrentPage(current_page);
        setPagination(links);
				setTotalPage(total);
				setPerPagePage(per_page);
      })
      .catch((error) => {       
        console.log('getAllIndicacao: ', error);
        setIsLoading(false);
      });
  }, [token]);

	const nextPage = useCallback(
    async () => {
			const tmpg = Math.ceil(totalPage / perPagePage);
			if (tmpg > currentPage) {
				setIsLoading(true);
				apiAsync
					.get(urlHistory, {
						headers: {Authorization: `Bearer ${token}`},
					})
					.then((res) => {
						const {data, links, current_page, total, per_page} = res.data.data;

						setList(data);
						setCurrentPage(current_page);
						setPagination(links);
						setTotalPage(total);
						setPerPagePage(per_page);
						setIsLoading(false);
					})
					.catch((error) => {        
						console.log('Indicacao asyncPagination: ', error);
						setIsLoading(false);
					});
			}
    },
    [token, currentPage, totalPage, perPagePage],
  );

	const asyncPagination = useCallback(
    async (url='',onSuccess=()=>{}) => {
      setIsLoading(true);
      apiAsync
        .get(url, {
          headers: {Authorization: `Bearer ${token}`},
        })
        .then((res) => {
          const {data, links, current_page} = res.data.data;

          setList(data);
          setCurrentPage(current_page);
          setPagination(links);          
          setIsLoading(false);
          onSuccess();
        })
        .catch((error) => {        
          console.log('Indicacao asyncPagination: ', error);
          setIsLoading(false);
        });
    },
    [token],
  );



	return(
		<IndicacaoContext.Provider 
			value={{
				getList,
				getIsLoading,
				getCurrentPage,
				getPagination,
				getAllIndicacao,
				asyncPagination,
				nextPage,
		}}>
			{children}
		</IndicacaoContext.Provider>
	);
}

export function useIndicacao() {
  const context = useContext(IndicacaoContext);
  if (!context) {
    throw new Error('useIndicacao must be used within an IndicacaoContext');
  }
  return context;
}