import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { Container, TopBar, Frame } from './styles';

function Chat() {
  const history = useHistory();

  const url = 'https://lnk.vollsc.com/w/hxv59';
  
  function onBackPressed() {
    history.goBack();
  }
  
  return (
    <Container>
      <TopBar>
        <MdArrowBack size={28} color="#fff" onClick={onBackPressed} />
      </TopBar>
      <Frame title="chat" src={url}/>
    </Container>
  );
}

export default Chat;