import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Carousel from '../../components/Carousel';
import Card from '../../components/Card';

import blind from '../../assets/blind.png';
import briefcase from '../../assets/briefcase.png';
import userIcon from '../../assets/user.png';
import AuthContext from '../../contexts/auth';
import ServicesContext from '../../contexts/services';
import SlidesContext from '../../contexts/slides';

// import api from '../../services/api';

function Home() {
  const { user } = useContext(AuthContext);
  const { structured } = useContext(ServicesContext);
  const { slides } = useContext(SlidesContext);
  const hist = useHistory();

  useEffect(() => {
    if (user?.first_time === false) {      
      hist.push('/intro');
    }
    // if (localStorage.getItem('firstTime') === null) {
    //   localStorage.setItem('firstTime', false);
    //   hist.push('/intro');
    // }
  }, [hist, user]);

  function formatTitle(title) {
    if (!title) {
      return '';
    }
    const arr = title.split(' ');
    arr.shift();
    return arr.join(' ');
  }

  function categoryImage(id) {
    switch (id) {
      case '1':
        return blind;
      case '2':
        return userIcon;
      case '3':
        return briefcase;
      default:
        return blind;
    }
  }

  return (
    <>
      <Container>
        <Header image={`${user.media_path}/${user.photo}`} title={`Olá ${user.name.split(' ')[0]}!`} state={0} route="/edit_profile"/>
        <h2 className="title">Escolha a área em que podemos te ajudar:</h2>
        <div className="cards">
          {Object.entries(structured).map(item => (
            <Card style={{cursor:"pointer"}} key={item[0]} onClick={() => hist.push(`/categorias/${item[0]}`)}>
              <img src={categoryImage(item[0])} alt={formatTitle(item[1].title)} />
              <div>
                <h3>{formatTitle(item[1].title)}</h3>
                <span>{Object.entries(item[1].children).length} serviços</span>
              </div>
            </Card>
          ))}
        </div>
        <Carousel slides={slides} />
      </Container>
      <Navbar />
    </>
  );
}

export default Home;