import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import Navbar from '../../components/Navbar';

import api from '../../services/api';

import { MdArchive, MdExpandMore } from 'react-icons/md';

import { Container, IconDoc, Buttons, Filename, ButtonsUpImg, Content, Help } from './styles';
import Loading from '../../components/Loading';

import checkCircle from '../../assets/check-circle.gif';

function UpDoc() {
  const hist = useHistory();

  const [upDoc, setUpDoc] = useState(false);
  const [docSize, setDocSize] = useState(false);
  const [options, setOptions] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(false);

  function closeModal() {
    setUpDoc(false);
    setOptions(false);
    setConfirmation(false);
    setDocSize(false);
  }

  function closeModalFileSize() {
    setUpDoc(false);
    setConfirmation(false);
    setDocSize(false);
    setOptions(true);
  }

  function upload() {
    const fileDone = document.getElementById(`selected-${type}`).files[0];

    //max = 50mb
    if (fileDone.size <= 50000000) {

      setOptions(false)
      setUpDoc(false)
      setIsLoading(true);

      const data = new FormData();
      data.append('file[]', fileDone);
      data.append('type_id', '2');

      api.post(`/users-send-files`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((res) => {
        setConfirmation(true)
      }).catch((error) => {
        if (error.response.status === 403) {
          setDocSize(true);
        }
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      setDocSize(true);
    }
  }

  return (
    <>
      {isLoading && (<Loading />)}
      {options && (
        <Modal onClick={closeModal}>
          <div>
            <span>Escolha a forma de envio!</span>
            <ButtonsUpImg>
              <label htmlFor='selected-camera'>
                Camera
              </label>
              <input id='selected-camera' accept="image/*" type='file' capture="camera" style={{ display: "none" }} onChange={() => { setUpDoc(true); setType("camera") }} />
              <label htmlFor='selected-file' >
                Arquivos
              </label>
              <input id='selected-file' accept="file/*" type='file' style={{ display: "none" }} onChange={() => { setUpDoc(true); setType("file") }} />
            </ButtonsUpImg>
          </div>
        </Modal>
      )}
      {upDoc && (
        <Modal onClick={closeModal}>
          <IconDoc>
            <MdArchive color="#011C3A" />
          </IconDoc>
          <div>
            <div>O arquivo selecionado possui o nome:</div>
            <Filename>{type === "file" ? document.getElementById('selected-file').files[0].name : "Foto da camera"}</Filename>
            <div>está correto?</div>
            <Buttons>
              <Button text="Cancelar" onClick={closeModal} />
              <Button text="Enviar" color={0} onClick={() => upload()} />
            </Buttons>
          </div>
        </Modal>
      )}
      {confirmation && (
        <Modal onClick={closeModal}>
          <Content>
            <img src={checkCircle} alt="Finalizado com sucesso" height={48} />
            <span className="title2">Sua documentação foi enviada com sucesso!</span>
            <span className="instructions">Em breve entraremos em contato.</span>
            <Button text="Voltar para a Home" color={0} onClick={() => hist.push('/')} />
          </Content>
        </Modal>
      )}
      {docSize && (
        <Modal onClick={closeModal}>
          <Content>
            <span className="title2">Arquivo muito grande!</span>
            <span style={{textAlign: 'center'}} className="instructions">Escolha outro arquivo e faça o envio novamente.</span>
            <Button text="Tentar novamente" color={0} onClick={closeModalFileSize} />
          </Content>
        </Modal>
      )}
      <Container>
        <Header title="Documentação" routeBack={true} />
        <h2 className="title">Envio de Documentação</h2>
        <Card>
          <div className="content">
            <span className="title2">Tirar foto do documento ou anexar arquivo do celular</span>
            <span className="instructions">Procure renomear o arquivo com o nome do documento para facilitar a identificação.</span>
            <Button text="Inserir Arquivo" color={0} onClick={() => setOptions(true)} />
          </div>
        </Card>
        <Help>
          <span className="title-help">Dúvidas?</span>
          <span>Estamos <span className="green">Online</span></span>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <MdExpandMore size={24} />
          </div>
        </Help>
      </Container>
      <Navbar />
    </>
  );
}

export default UpDoc;
