import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdNotificationsNone, MdMenu, MdKeyboardArrowLeft } from 'react-icons/md';

import NotificationsContext from '../../contexts/notifications';
import DrawerMenu from '../DrawerMenu';

import { Container, UserImage, NotificationIndicator, Nav } from './styles';

function Header({ title, state, route, routeBack, routeBackNotification, image }) {
 
  const { hasUnseen } = useContext(NotificationsContext);
  const [menuOpened, setMenuOpened] = useState(false);
  const hist = useHistory();

  return (
    <>
      <Container>
        <span onClick={() => routeBack ? hist.goBack() :  hist.push(route)} className="link">
          <div className="left">
            {state === 0 ?
              <UserImage src={image} />
              : <MdKeyboardArrowLeft color="#FFF" size={34} />
            }
            <h2>{(title.slice(0, 14) + (title.length > 14 ? "..." : ""))}</h2> 
          </div>
        </span>
        <Nav>
          <ul>
            <li onClick={() => hist.push("/contact_us")}>
              <label>Contato</label>
            </li>
            <li onClick={() => hist.push("/")}>
              <label>Home</label>
            </li>
            <li onClick={() => window.open('https://api.whatsapp.com/send?phone=5511950841440', '_blank')}>
              <label>Chat</label>
            </li>
          </ul>
        </Nav>
        <div className="right">
          <MdNotificationsNone
            color="#FFF"
            size="30px"
            onClick={() => routeBackNotification ? hist.goBack() : hist.push('/notifications')}
          />
          { hasUnseen && <NotificationIndicator/> }
          <MdMenu color="#FFF" size="30px" onClick={() => setMenuOpened(true)} />
        </div>
      </Container>
      <DrawerMenu isOpened={menuOpened} closeCallback={() => setMenuOpened(false)} />
    </>
  );
}

export default Header;