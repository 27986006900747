import styled from 'styled-components';

export const Wrapper = styled.div`
  .background-enter {
    opacity: 0;
  }
  .background-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .background-exit {
    opacity: 1;
  }
  .background-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .menu-enter {
    transform: translateX(100%);
  }
  .menu-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
  }
  .menu-exit {
    transform: translateX(0);
  }
  .menu-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const Container = styled.div`
  height: 100%;
  width: 300px;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  padding: 47px 0 22px 0;
  z-index: 5;

  @media only screen and (max-width: 300px) {
    font-size: 16px;
  }


  @media only screen and (max-width: 300px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 28px;

  svg {
    align-self: flex-end;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    h2 {
      color: #002854;
      font-weight: bold;
      margin-left: 8px;
      font-size: 22px;
    }
  }
`;

export const Body = styled.div`
  margin-top: 34px;

  a {
    color: #2b2b2b;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
  }

  li {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 20px 28px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
  } 

  & li:hover {
    background-color: #C59742;   
  };
`;

export const Footer = styled.div`
  padding: 36px 28px;
  flex-grow: 1;

  .terms {
    display: flex;
    flex-direction: column;

    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #2B2B2B;
    }

    span {
      color: #797979;
    }
  }

  .social {
    margin-top: 54px;

    span {
      color: #2B2B2B;
    }

    div {
      margin: 8px 0;
      display: flex;
      gap: 8px;

      > a{
        color: #2B2B2B;
        text-decoration: none;
      }
    }

    a {
      color: #C59742;
      text-decoration: none;
    }
  }
`;

export const UserImage = styled.image`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  min-height: 50px;
  min-width: 50px;
  border-radius: 40px;
  background-color: #C59742;
`;