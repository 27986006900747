import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 0 16px;
  background: linear-gradient(180deg, #002854 28.13%, #000D1B 100%);
  min-height: 100vh;

  > div{
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }

  .title{
    font-size: 24px;
    color: #FFF;
    width: 275px;
    margin-bottom: 17px;
    margin-left: 5px;
  }
  
  .content{
    display:flex;
    flex-direction: column;

    .title2{
      font-size: 16px;
      font-weight: 700;
    }

    .instructions{
      margin-top: 10px;
      color: #797979;
      font-size: 12px;
    }
    
    .date-time{
      font-size: 10px;
      color: #797979;
      margin-top: 10px;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 157px);
  overflow: auto;
  
  .infinite-scroll-component > div {
    margin-bottom: 8px;
  }
`;

export const File = styled.a`
  background: #C59742;
  color: #fff;
  padding: 6px;
  margin-top: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
`;