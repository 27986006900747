import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 105px 16px;
  min-height: 100vh;

  text-decoration: none;

  a{
    text-decoration: none;
  }
`;

export const Cards = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 12px;
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-column-gap: 15px; */
  /* grid-row-gap: 12px; */
  overflow: scroll;
  padding-bottom: 80px;

  > div {
    width: calc(50% - 8px);
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0 28px 0;
  gap: 15px;
  text-align: center;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #002854;
  }
`;

export const Error = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #D61F2A;
`;

export const Icon = styled.div`
  text-align: center;
`;

export const TextModal = styled.div`
  text-align: center;

  span{
    font-weight: 700;
    width: 200px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;