import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(180deg, #002854 28.13%, #000D1B 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    margin: 10vh 0 24px 45px;
  }

  section {
    margin: 0 45px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .slider-enter {
      transform: translateX(100%);
    }
    .slider-enter-active {
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }
    .slider-exit {
      transform: translateX(100%);
    }
    .slider-exit-active {
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms;
    }
  }

  footer {
    margin-top: 10vh;
    margin-bottom: 74px;
    text-align: center;
  }

  a {
    color: #CAA660;
    text-decoration: none;
  }

  .link {
    font-size: 13px;
    line-height: 15px;
    color: #C4C4C4;
    display: flex;
    align-items: center;
  }
`;

export const Slide = styled.div`
  color: #E5E5E5;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    display: block;
    font-weight: 900;
  }

  .size40 {
    font-size: 40px;
  }

  .size26 {
    font-size: 26px;
  }

  .size30 {
    font-size: 30px;
  }
`;

export const SlideController = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;

  div {
    display: flex;
    gap: 8px;
  }
`;

export const SlideCursor = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => props.checked ? '#C4C4C4' : 'transparent'};
  border: 1px solid #C4C4C4;
  border-radius: 50%;
  box-sizing: border-box;
`;