import React, { useCallback, useEffect } from 'react';
import { uuid } from 'uuidv4';
import Table,{Th,Td, TableResposive} from '../Table';

import {useIndicacao} from '../../contexts/indicacao';
import NavPagination from '../NavPagination';

const HistoryList = ()=>{
  const {
    getList,
    getPagination,
    getCurrentPage,
    asyncPagination,
    getAllIndicacao,
    getIsLoading,
  } = useIndicacao();
  const list = getList();
  const links = getPagination();
  const current_page = getCurrentPage();

  
  useEffect(()=>{
    getAllIndicacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const formateDate = useCallback((str) => {
    try {
      if (str) {
        const date = str.slice(0, 10);
        const time = str.slice(11, 16);
        const newDate = date.split('-');
        return `${time} ${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      }
    } catch (error) {
      //
    }

    return '';
  }, []);

  return(
    <div> 
      <h3>Histórico de indicação</h3>     
      <TableResposive>
        <Table>
            <thead>
              <tr>
                  <Th>#</Th>
                  <Th>Nome</Th>
                  <Th>Email</Th>
                  <Th>Telefone</Th>
                  <Th>Celular</Th>
                  <Th>Enviado em</Th>
              </tr>
            </thead>
            <tbody>
              {getIsLoading() ? (
                  <p>Carrengando...</p>
              ) : list.length > 0 ? (
              list.map((item) => (
              <tr key={`${item.id}-${uuid()}`}>
                  <Th>{item.id}</Th>
                  <Td>{item.name}</Td>
                  <Td>{item.email}</Td>
                  <Td>{item.telefone}</Td>
                  <Td>{item.celular}</Td>
                  <Td>{formateDate(item.created_at || '')}</Td>
              </tr>
              ))
              ) : (
              <tr>
                  <Td colSpan={6}>Nenhum dados encontrados!</Td>
              </tr>
              )}
            </tbody>
        </Table>
      </TableResposive>
      <div>
        <NavPagination
          links={links}
          current_page={current_page}
          asyncPagination={asyncPagination}
          isScrollBottom
        />
      </div>
      
    </div>
  );
}

export default HistoryList;