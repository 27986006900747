import firebase from 'firebase/app';

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {

      // navigator.serviceWorker.register('./app/firebase-messaging-sw.js').then(function (registration) {
      //   // Registration was successful
      //   console.log('Firebase ServiceWorker registration successful with scope: ', registration.scope);
      //   const messaging = firebase.messaging();
      //   messaging.useServiceWorker(registration);
      // });

      navigator.serviceWorker.register('/app/sw.js').then(function (registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);

        const messaging = firebase.messaging();
        messaging.useServiceWorker(registration);

      }).catch(function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      });
    });
  }
}