import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import { MdHome, MdMailOutline, MdMail, MdChatBubbleOutline } from 'react-icons/md';

function Navbar({ route }) {
  return (
    <Container>
      <footer>
        <Link to="/contact_us">
          {route === "/contact_us" ?
            <MdMail size={35} color="#002854" />
            : <MdMailOutline size={35} color="#002854" />
          }
        </Link>
        <Link to="/" >
          <MdHome size={35} color="#002854" />
        </Link>
        <a href="https://api.whatsapp.com/send?phone=5511950841440" target="_blank">
          <MdChatBubbleOutline size={35} color="#002854" />
        </a>
      </footer>
    </Container>
  );
}

export default Navbar;