import React from 'react';
import { TableContainer, ThContainer as Th, TdContainer as Td, TableResposive} from './styles';

function Table({ children, style }) {
  return (
    <TableContainer style={style}>
      { children }
    </TableContainer>
  );
}

export default Table;

export {Th, Td, TableResposive};