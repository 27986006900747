import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 10px;
    line-height: 12px;
    color: #002854;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  div {
    display: flex;
    align-items: center;
    
    label {
      font-size: 13px;
      line-height: 15px;
      color: #797979;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  input[type='radio']{
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #E5E5E5;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: #02DAC3;
  }

  input[type="radio"]:checked {
    border-color: #E5E5E5;
  }
`;
