import React from 'react';

import { Container } from './styles';

function Card({ children, small, onClick, style }) {
  return (
    <Container isSmall={small} onClick={onClick} style={style}>
      { children }
    </Container>
  );
}

export default Card;