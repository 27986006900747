import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdPhone, MdEmail, MdChatBubbleOutline, MdPeople } from 'react-icons/md';
import { IoLogoLinkedin, IoLogoInstagram, IoLogoFacebook, IoLogoYoutube } from 'react-icons/io';
import { FaWhatsapp, FaBalanceScale } from 'react-icons/fa';
import { AiOutlineGlobal } from 'react-icons/ai';

import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import DrawerMenu from '../../components/DrawerMenu';

import { Container, Cards, CardContent } from './styles';
import Card from '../../components/Card';

function ContactUs() {
  const [menuOpened, setMenuOpened] = useState(false);
  const hist = useHistory();

  return (
    <>
      <Container>
        <Header title="Fale Conosco" routeBack={true} menuCallback={() => setMenuOpened(true)} />
        <Cards>
        <Card small>
            <a href="tel:1131555533" rel="noreferrer" target="_blank">
              <CardContent>
                <MdPhone size={40} color="#C59742" />
                <span>Ligar (São Paulo)</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="tel:30030152" rel="noreferrer" target="_blank">
              <CardContent>
                <MdPhone size={40} color="#C59742" />
                <span>Ligar (outras localidades)</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="https://api.whatsapp.com/send?phone=5511950841440" rel="noreferrer" target="_blank">
              <CardContent>
                <FaWhatsapp size={40} color="#C59742" />
                <span>Whatsapp</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="mailto:duvidas@salgadojunior.com.br" rel="noreferrer" target="_blank">
              <CardContent>
                <MdEmail size={40} color="#C59742" />
                <span>E-mail</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="https://api.whatsapp.com/send?phone=5511950841440" rel="noreferrer" target="_blank">
              <CardContent>
                <MdChatBubbleOutline size={40} color="#C59742" />
                <span>Chat</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="mailto:selecao@salgadojunior.com.br" rel="noreferrer" target="_blank">
              <CardContent>
                <FaBalanceScale size={40} color="#C59742" />
                <span>Trabalhe Conosco</span>
              </CardContent>
            </a>
          </Card>
          <Card small>          
            <CardContent style={{cursor:"pointer"}} onClick={()=>{
              hist.push('/indicacao')
            }}>
              <MdPeople size={40} color="#C59742" />
              <span>Indique</span>
            </CardContent>
          </Card>
          <Card small>
            <a href="https://www.salgadojunior.com.br/" rel="noreferrer" target="_blank">
              <CardContent>
                <AiOutlineGlobal size={40} color="#C59742" />
                <span>Nosso Site</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="https://www.linkedin.com/company/salgadojradv" rel="noreferrer" target="_blank">
              <CardContent>
                <IoLogoLinkedin size={40} color="#C59742" />
                <span>LinkedIn</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="https://www.facebook.com/salgadojunioradv" rel="noreferrer" target="_blank">
              <CardContent>
                <IoLogoFacebook size={40} color="#C59742" />
                <span>Facebook</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="https://www.instagram.com/salgadojunioradv/" rel="noreferrer" target="_blank">
              <CardContent>
                <IoLogoInstagram size={40} color="#C59742" />
                <span>Instragram</span>
              </CardContent>
            </a>
          </Card>
          <Card small>
            <a href="https://www.youtube.com/channel/UCXPI6nIUnX0-U-VvLeh7ocQ"  rel="noreferrer" target="_blank">
              <CardContent>
                <IoLogoYoutube size={40} color="#C59742" />
                <span>Youtube</span>
              </CardContent>
            </a>
          </Card>
        </Cards>
      </Container>
      <Navbar />
      <DrawerMenu isOpened={menuOpened} closeCallback={() => setMenuOpened(false)} />
    </>
  );
}

export default ContactUs;