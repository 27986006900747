import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 12px 16px;

  .title-and-img{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 25px;

    input[type='file'] {
      display: none
    }

    h1{
      color: #FFF;
      font-size: 24px;
      margin-bottom: 12px;
      margin-left: 9px;
    }

    > div {
      text-align: center;
      margin-bottom: -30px;

      /* .img-profile-big{
        height: 80px;
        width: 80px;
        border-radius: 80px;
        background-color: #C59742;
      } */

      span{
        font-size: 10px;
        margin-top: 2px;
        color: #002854;
        display: block;
      }
    }
  }

  .button-edit{
    margin-top: 18px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

`;

export const Code = styled.div`
  flex-grow: 1;

  label {
    display: block;
    font-size: 10px;
    line-height: 12px;
    color: #002854;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  span {
    display: block;
    font-size: 13px;
    line-height: 15px;
    color: #2B2B2B;
  }
`;


export const Icon = styled.div`
  text-align: center;
`;

export const TextModal = styled.div`
  text-align: center;

  span{
    font-weight: 700;
    width: 200px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

export const Error = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #D61F2A;
`;

export const UserImage = styled.image`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: inline-block;

  height: 80px;
  width: 80px;
  border-radius: 80px;
  background-color: #C59742;
`;

export const ButtonsUpImg = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 15px;

  label{
    width: 110px;
    height: 43.06px;
    left: 94.44px;
    top: 550.97px;

    background: #002854;
    border-radius: 70px;

    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
  }

`;
