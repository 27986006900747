import { createContext, useCallback, useContext, useEffect, useState } from "react";
import api from "../services/api";
import { isAuthenticated } from "../services/auth";

import { getToken, onMessageListener } from "../services/firebase";
import AuthContext from "./auth";

const NotificationsContext = createContext({
  registration: null,
  notifications: [],
  currentPage: 1,
  lastPage: 1,
  hasUnseen: false,
  next: () => {},
  hasMore: () => {},
  setHasUnseen: () => {},
});

export const NotificationsProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [token, setToken] = useState(null);
  const [hasUnseen, setHasUnseen] = useState(false);

  const listNotifications = useCallback(() => {
    if (isAuthenticated() && user) {
      api
        .get(`/notificacao/user/${user.id}?page=${currentPage}`)
        .then((res) => {
          const { data, last_page: lastPage } = res.data.data;

          if (data.find(item => item.view == null)) {
            //console.log('Notificações não vistas');
            setHasUnseen(true);
          }

          if (currentPage > 1) {
            setNotifications(old => [...old, ...data]);
          } else {
            setNotifications(data);
          }
          
          setLastPage(lastPage);
        })
        .catch((error) => {
          console.log('listNotifications: ', error);
          console.log('listNotifications: ', error.response.data);
        });
    }
  }, [user, currentPage]);

  useEffect(() => {
    if (isAuthenticated()) {
      getToken(setToken);
      setNotificationListener();
    }
    listNotifications();
  }, [user, listNotifications]);

  useEffect(() => {
    if (isAuthenticated() && user && token) {
      //console.log(user);
      //console.log(user.device_token);
      //console.log(token);

      const data = new FormData();
      data.append('device_os', user.device_os);
      data.append('device_token', token);

      api
        .post(`/user-save-id`, data)
        .catch((error) => console.error(error));
    }
  }, [token, user]);

  function setNotificationListener() {
    if (isAuthenticated()) {
      onMessageListener().then(message => {
        console.log(message);
        setHasUnseen(true);
      }).catch((error) => console.log('firebaseError: ', error));
    }
  }

  function hasMore() {
    return currentPage < lastPage;
  }

  function next() {
    if (hasMore()) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <NotificationsContext.Provider value={{ notifications, currentPage, lastPage, hasUnseen, next, hasMore, setHasUnseen }}>
      { children } 
    </NotificationsContext.Provider>
  );
}

export default NotificationsContext;