// import React, { useEffect, useState } from 'react';
import Routes from './routes.js';
// import NotMobile from './pages/NotMobile';
// import Modal from './components/Modal';

import './global.css';
// import { Container } from './styles';

function App() {
  //console.log("navigator: ",navigator.userAgent);
  //console.log("i: ",/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  // const [modalInstall, setModalInstall] = useState(true);

  // function closeModal() {
  //   setModalInstall(false);
  // }

  // useEffect(() => {
  //   setTimeout(() => {
      // var pwaIn = document.getElementById("pwa");
      
      // if (!pwaIn.getInstalledStatus()) {
        // pwaIn.openPrompt();
        // setModalInstall(true);
      // }
  //   }, 5000);
  // });
  return (
    <>
      <div className="background-gradient"></div>
      <div className="App">       
        <Routes />        
      </div>
    </>
  );

  // return (
  //   <>
  //     <div className="background-gradient"></div>
  //     <div className="App">
  //       {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ?
  //         <Routes />
  //         : <NotMobile />}
  //     </div>
  //   </>
  // );
}

export default App;