import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdMailOutline } from 'react-icons/md';

import Card from '../../components/Card';
import TextInput from '../../components/TextInput';
import logo from '../../assets/logo.png';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import checkCircle from '../../assets/check-circle.gif';
import Loading from '../../components/Loading';

import { Container, Error, Message, Icon, TextModal, Buttons } from './styles';

import api from '../../services/api';

function RecoveryPassword() {
  const [message, setMessage] = useState(null);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [confirmation, setConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hist = useHistory();

  function onSubmit(data) {
    setIsLoading(true);

    api
      .get(`forgot-password/${data.email}`)
      .then((res) => {
        setConfirmation(true)
        setMessage('Enviamos um link de recuperação de senha no seu email!');
      })
      .catch((error) => {
        setMessage('O email não existe');
      }).finally(() => {
        setIsLoading(false);
      });
  }

  function closeModal() {
    setConfirmation(false)
  }

  return (
    <>
      {isLoading && (<Loading />)}
      {confirmation && (
        <Modal onClick={closeModal}>
          <Icon><img src={checkCircle} alt="Finalizado com sucesso" height={48} /></Icon>
          <TextModal>
            <span>Solicitação enviada com sucesso!</span>
            <div className="help-text">Você receberá um e-mail para alterar sua senha. Dúvidas? Contate o suporte.</div>
          </TextModal>
          <Buttons>
            <Button text="Voltar para o Login" color={0} onClick={() => hist.push('/login')} />
          </Buttons>
        </Modal>
      )}
      <Container>
        <img src={logo} alt="Logo App Salgado" />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {message && <Message>{message}</Message>}
            <TextInput
              register={register}
              validation={{
                required: {
                  value: true, message: 'O campo email não pode ser vazio!',
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: 'Digite um endereço de email válido!',
                },
              }}
              id="email"
              label="Email"
              placeholder="@"
              icon={<MdMailOutline size={18} color="#797979" />}
            />
            {errors.email && <Error>{errors.email?.message}</Error>}
            <div className="button-link">
              <Button text="Enviar link para o E-mail" />
            </div>
          </form>
        </Card>
      </Container>
    </>
  );
}

export default RecoveryPassword;