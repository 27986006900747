import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA939cXHq2pSA_7KiOwzC8YTSSDF_fYzg0",
  authDomain: "appprevsimples-d23fd.firebaseapp.com",
  databaseURL: "https://appprevsimples-d23fd.firebaseio.com",
  projectId: "appprevsimples-d23fd",
  storageBucket: "appprevsimples-d23fd.appspot.com",
  messagingSenderId: "157201042875",
  appId: "1:157201042875:web:3a9573a7ec6f40df6e489e",
  measurementId: "G-MCR1R5MMSX"
};

const vapidKey = 'BBEGhsDKsAgsHp6WD_jMNIqsheAZ8BlZE8oAtriMAxJyxIdehrEIvyale4QtB58mRvRZJbrJwxHC7PrMD5D55Qo';

export const initializeFirebase = () => {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (error) {
    console.error('initializeFirebase: ', error);
  }
}

export const getToken = (setToken) => {
  try {
    const messaging = firebase.messaging();
    return messaging.getToken({ vapidKey }).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setToken(currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setToken(currentToken);
      }
    }).catch((error) => {
      console.log('An error occurred while retrieving token. ', error);
    });
  } catch (error) {
    console.error('getToken: ', error);
    return Promise.resolve(undefined);
  }
}

export const onMessageListener = () => {
  try {
    const messaging = firebase.messaging();
    return new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
  } catch (error) {
    console.error('onMessageListener: ', error);
    return Promise.resolve(undefined);
  }
}