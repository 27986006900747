import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: 'https://app.salgadojunior.com.br/public/api/'
  //baseURL: 'https://salgadojunior.com.br/sistema/backend/public/api/'
  //baseURL: 'http://localhost/salgadojuniorbackend/public/api/'
});

export const apiAsync = axios.create({
  baseURL: '',
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;