import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 105px 16px;

  .title{
    font-size: 20px;
    color: #FFF;
    width: 275px;
    margin-bottom: 17px;
    margin-left: 15px;
  }

  .content{
    display:flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;

    .title2{
      font-size: 16px;
      font-weight: 700;
    }

    .instructions{
      margin-top: 5px;
      margin-bottom: 24px;
      color: #797979;
      font-size: 12px;
    }

    > div{
      display: flex;
      justify-content: center; 
      
      label {
        width: 185.98px;
        height: 43.06px;
        background-color: #C59742;
        border-radius: 70px;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        line-height: 15px;
        color: #FFFFFF;
      }

      input[type='file'] {
        display: none
      }
    }
  }
`;

export const Filename = styled.span`
  font-weight: 800;
`;

export const IconDoc = styled.div`
  text-align: center;
  font-size: 58px;
`;

export const Icon = styled.div`
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button{
    width: 131px;
  }

`;

export const ButtonsUpImg = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 15px;

  label{
    width: 110px;
    height: 43.06px;
    left: 94.44px;
    top: 550.97px;

    background: #002854;
    border-radius: 70px;

    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

export const TextModal = styled.div`
  text-align: center;

  span{
    font-weight: 700;
    width: 200px;
  }
`;

export const Content = styled.div`
  display:flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  width: 240px;
  
  .title2{
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 170px;
  }
  .instructions{
    margin-top: 10px;
    margin-bottom: 24px;
    color: #797979;
    font-size: 12px;
  }
`;

export const Help = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  bottom: 90px;

  .title-help {
    font-size: 18px;
  }

  .green {
    color: #69A52D;
  }
`;