import { createContext, useContext, useEffect, useState } from "react";

import AuthContext from "./auth";

import api from "../services/api";
import { isAuthenticated } from "../services/auth";

const AdressesContext = createContext({
  adresses: [],
  loading: true,
  refresh: () => {}
});

export const AdressesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const [adresses, setAdresses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadAdresses();
  }, [user]);

  function loadAdresses() {
    if (isAuthenticated()) {
      setLoading(true);
  
      api
        .get('address').then((response) => {
          setAdresses(response.data.data.data);
        }).catch((error) => {
          console.log('getAdresses: ', error);
          console.log('getAdresses: ', error.response.data);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <AdressesContext.Provider value={{ adresses, loading, refresh: loadAdresses }}>
      { children }
    </AdressesContext.Provider>
  );
}

export default AdressesContext;