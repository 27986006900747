import { createContext, useContext, useEffect, useState } from "react";

import api from "../services/api";
import { isAuthenticated } from "../services/auth";
import AuthContext from "./auth";

const ServicesContext = createContext({
  services: [],
  structured: {},
  loading: true,
  refresh: () => {}
});

export const ServicesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [services, setServices] = useState([]);
  const [structured, setStructured] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadServices();
  }, [user]);

  useEffect(() => {
    const structServices = {};

    services.forEach(service => {
      const { title } = service;
      if (title.startsWith('#')) {
        const categories = title.substring(1);
        const [summary] = categories.split(' ');
        if (summary.indexOf('.') !== -1) {
          const path = summary.split('.');
          let target = structServices;
          for (let i = 0; i < path.length - 1; i++) {
            if (i > 0) {
              target = target.children[path[i]];
            } else {
              target = target[path[i]];
            }
          }
          if (!target) return;
          target.children[path[path.length - 1]] = service; 
          target.children[path[path.length - 1]].children = {}; 
        } else {
          structServices[summary] = service;
          structServices[summary].children = {};
        }
      }
    });
    setStructured(structServices);
  }, [services]);

  function loadServices() {
    if (isAuthenticated()) {
      setLoading(true);
  
      api
        .get('services').then((response) => {
          const { current_page, last_page, data } = response.data.data;
          const servicesList = data;

          const promises = [];

          for (let i = current_page; i <= last_page; i++) {
            promises.push(api.get(`services?page=${i}`));
          }

          Promise.all(promises).then((res) => {
            res.forEach(resp => {
              const { data } = resp.data.data;
              servicesList.push(...data);
            });
            setServices(servicesList);
          }).catch((error) => {
            console.log('getServices: ', error);
            console.log('getServices: ', error.response.data);
          }).finally(() => {
            setLoading(false);
          });
        }).catch((error) => {
          console.log('getServices: ', error);
          console.log('getServices: ', error.response.data);
        });
    }
  }

  return (
    <ServicesContext.Provider value={{ services, structured, loading, refresh: loadServices }}>
      { children }
    </ServicesContext.Provider>
  );
}

export default ServicesContext;