import styled from 'styled-components';

export const Container = styled.div`
  padding: 38px 16px 105px 16px;
  height: 100vh;
  
  .cards{
    width: 100%;
    display:flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 19px;
    overflow: scroll;
    height: 100%;
    padding-bottom: 80px;
    
    > div{
      border-radius: 12px;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
      padding: 20px 22px;

      a{
        text-decoration: none;
        user-select: none;
        color: #2B2B2B;
        display: flex;
        align-items: flex-start;
      }
      
      div{
        flex-direction: column;
        padding-left: 13px;
        width: 100%;

        h3{
          font-weight: 500;
        }

        span{
          color: #797979;
          font-size: 14px;
        }
      }
    }
  }
`;
