import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {IndicacaoProvider} from './contexts/indicacao';
import { registerServiceWorker } from "./serviceWorker.js";
import { initializeFirebase } from "./services/firebase";

ReactDOM.render(
  <React.StrictMode>
    <IndicacaoProvider>
      <App />
    </IndicacaoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

registerServiceWorker();
initializeFirebase();