import { createContext, useEffect, useState } from 'react';

import api from '../services/api';
import { isAuthenticated, logout } from '../services/auth';

const AuthContext = createContext({ 
  signed: false,
  user: null,
  setUser: () => {},
  loading: true,
  setLoading: () => {},
  refreshUser: () => {}
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshUser();
  }, []);

  function refreshUser() {
    if (isAuthenticated()) {
      api
        .get('/show-me')
        .then((res) => {
          const { data } = res.data;
          setUser(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 401) {
            logout();
          }
        });
    }
  }

  function updateFirstTime(value = false,onSucess){
    setLoading(true);
      api
      .post('/update-first-time',{
        first_time: value,
      })
      .then((res) => {
        setLoading(false);
        setUser((old)=> ({...old,first_time:value}));
        onSucess();
      })
      .catch((error) => {
        setLoading(false);   
        onSucess();     
      });
  }

  return (
    <AuthContext.Provider value={{ signed: user ? true : false, user, setUser, loading, setLoading, refreshUser, updateFirstTime }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;