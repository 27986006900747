import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdLock, MdMailOutline } from 'react-icons/md'

import AuthContext from '../../contexts/auth';

import Card from '../../components/Card';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { login } from '../../services/auth';

import { Container, Error } from './styles';

import logo from '../../assets/logo.png';

function Login() {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const { setUser, setLoading } = useContext(AuthContext);
  const hist = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();

  function onSubmit(data) {
    setIsLoading(true);

    localStorage.setItem('email_login', data.email);
    
    api.post('/login', data).then((res) => {
      const { user, token } = res.data;
      login(token);
      setUser(user);
      setLoading(false);
      hist.push('/');
    }).catch((error) => {
      setMessage('Login ou senha incorretos!');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Container>
      {isLoading && (<Loading />)}
      <img src={logo} alt="Logo App Salgado" />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {message && <Error>{message}</Error>}
          <TextInput
            register={register}
            validation={{
              required: {
                value: true, message: 'O campo email não pode ser vazio!',
              },
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, message: 'Digite um endereço de email válido!',
              },
            }}
            id="email"
            label="*Email / Login"
            placeholder="@"
            defaultValue={localStorage.getItem('email_login')}
            icon={<MdMailOutline size={18} color="#797979"/>}
          />
          {errors.email && <Error>{errors.email?.message}</Error>}
          <TextInput
            register={register}
            validation={{ required: { value: true, message: 'O campo senha não pode ser vazio!' } }}
            id="password"
            label="*Senha"
            placeholder="*********"
            type="password"
            icon={<MdLock size={18} color="#797979" />}
          />
          {errors.password && <Error>{errors.password?.message}</Error>}
          <span onClick={() => hist.push("/recovery_password")} className="forget-password link">Esqueci minha senha</span>
          <div className="button-register">
            <Button text="Entrar" />
            <span onClick={() => hist.push("/register")} className="link">Novo Cadastro</span>
          </div>
        </form>
      </Card>
    </Container>
  );
}

export default Login;