import styled from 'styled-components';

export const Container = styled.div`
  padding: 43px 18px;
  display:flex;
  flex-direction: column;

  img {
    margin-bottom: 30px;
    margin-top: 30px;
    width: 132px;
    align-self: center;
  }

  .forget-password {
    text-align: end;
    width: 100%;
    display: block;
    margin: 8px 0;
  }

  .button-register{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 26px;
    gap: 16px;
  }

  .link {
    font-size: 13px;
    line-height: 15px;
    color: #002854;
    text-decoration: none;
    margin-bottom: 17px;
  }
`;

export const Error = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #D61F2A;
`;