import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import ServicesContext from '../../../contexts/services';

import { useHistory } from 'react-router-dom';

import Card from '../../../components/Card';
import Header from '../../../components/Header';
import Navbar from '../../../components/Navbar';
import Button from '../../../components/Button';

import { Container, Cards, Content, ButtonContainer } from './styles';

function PageContent() {
  const [service, setService] = useState(null);
  const { structured } = useContext(ServicesContext);
  const { categoria, servico, subservico } = useParams();
  const hist = useHistory();

  useEffect(() => {
    if (structured[categoria]) {
      if (subservico) {
        setService(structured[categoria].children[servico].children[subservico]);
      } else {
        setService(structured[categoria].children[servico]);
      }
    }
  }, [structured, categoria, servico, subservico]);

  function formatTitle(title) {
    if (!title) {
      return '';
    }
    const arr = title.split(' ');
    arr.shift();
    return arr.join(' ');
  }

  if (!service) {
    return (
      <>
        <Container>
          <Header title={formatTitle(structured[categoria] ? structured[categoria].title : '')} routeBack={true} />
        </Container>
        <Navbar />
      </>
    );
  }

  return (
    <>
      <Container>
        <Header title={formatTitle(structured[categoria] ? structured[categoria].title : '')} routeBack={true} />
        <h2 className="title">{formatTitle(service?.title)}</h2>
        <Cards className="cards">
          <Card>
            <Content>
              <span className="title2">O que é</span>
              <div
                dangerouslySetInnerHTML={{ __html: service?.information ?? '' }}
              ></div>
            </Content>
          </Card>
          <Card>
            <Content>
              <span className="title2">Documentação Necessária</span>
              <div
                className="doc"
                dangerouslySetInnerHTML={{ __html: service?.documentation ?? '' }}
              ></div>
            </Content>
            <ButtonContainer>
              <Button onClick={() => hist.push('/upload_doc')} color={0} text="Enviar documentação" />
            </ButtonContainer>
          </Card>
        </Cards>
      </Container>
      <Navbar />
    </>
  );
}

export default PageContent;