import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { MdLock, MdPhone, MdAccountCircle, MdMailOutline, MdAccountBox } from 'react-icons/md';
import imageCompression from 'browser-image-compression';

import Card from '../../components/Card';
import TextInput from '../../components/TextInput';
import MaskedInput from '../../components/MaskedInput';
import RadioInput from '../../components/RadioInput';
import Button from '../../components/Button';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

import AuthContext from '../../contexts/auth';
import Modal from '../../components/Modal';

import api from '../../services/api';

import { Container, Code, Icon, TextModal, Buttons, ButtonsUpImg, Error, UserImage } from './styles';
import checkCircle from '../../assets/check-circle.gif';

const compressionOptions = {
  maxSizeMB: 0.5,
}

function EditProfile() {
  const { register, control, handleSubmit, formState: { errors } } = useForm();
  const { user, refreshUser } = useContext(AuthContext);
  const hist = useHistory();
  const [upImg, setUpImg] = useState(false);

  const [confirmation, setConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(data) {
    const form = new FormData();
    form.append('name', data.nome);
    form.append('email', data.email);
    form.append('is_customer', `${data.cliente === 'sim' ? 1 : 0}`);
    form.append('cpf', data.cpf);
    form.append('telefone', data.telefone);
    form.append('email_inss', data.emailinss);
    form.append('password_inss', data.senhainss);

    if (data.senha && data.senha !== "") {
      form.append('password', data.senha);
      form.append('password_confirmation', data.senha);
    }

    setIsLoading(true);
    api
      .post(`/users/update/${user.id}`, form)
      .then(() => {
        refreshUser();
        setConfirmation(true)
      })
      .catch((error) => {
        console.log('updateUser: ', error);
        console.log('updateUser: ', error.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function closeModal() {
    setConfirmation(false);
    setUpImg(false);
  }

  function uploadImg(type) {
    const imgDone = document.getElementById(`selected-${type}`).files[0];

    setUpImg(false);
    setIsLoading(true);

    imageCompression(imgDone, compressionOptions).then((res) => {
      const data = new FormData();
      data.append('file', res);
      
      api.post(`/users/profile/${user.id}`, data).then((res) => {
        refreshUser();
        setConfirmation(true);
      }).catch((error) => {
        console.log('uploadUserPhoto: ', error);
        console.log('uploadUserPhoto: ', error.response.data);
      }).finally(() => {
        setIsLoading(false);
      });
    }).catch((error) => {
      console.log('imageCompression: ', error);
    })
  }

  return (
    <>
      {isLoading && (<Loading />)}
      {confirmation && (
        <Modal onClick={closeModal}>
          <Icon><img src={checkCircle} alt="Finalizado com sucesso" height={48} /></Icon>
          <TextModal>
            <span>Atualizado com sucesso!</span>
          </TextModal>
          <Buttons>
            <Button text="Voltar para a Home" color={0} onClick={() => hist.push('/')} />
          </Buttons>
        </Modal>
      )}
      {upImg && (
        <Modal onClick={closeModal}>
          <div>
            <span>Escolha a forma de envio!</span>
            <ButtonsUpImg>
              <label htmlFor='selected-camera'>
                Camera
              </label>
              <input id='selected-camera' accept="image/*" type='file' capture="camera" style={{ display: "none" }} onChange={() => uploadImg('camera')} />
              <label htmlFor='selected-file' >
                Arquivos
              </label>
              <input id='selected-file' accept="image/*" type='file' style={{ display: "none" }} onChange={() => uploadImg('file')} />
            </ButtonsUpImg>
          </div>
        </Modal>
      )}
      <Container>
        <Header title="Perfil" state={1} route="/" />
        <div className="title-and-img">
          <h1>Editar Cadastro</h1>
          <div>
            <label onClick={() => setUpImg(true)} >
              <UserImage src={`${user.media_path}/${user.photo}`} />
              <span>Alterar foto</span>
            </label>
          </div>
        </div>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              register={register}
              validation={{ required: true }}
              id="nome"
              label="*Nome Completo"
              placeholder="Digite seu nome"
              icon={<MdAccountCircle size={18} color="#797979" />}
              defaultValue={user.name}
            />
            {errors.nome && <Error>O campo nome não pode ser vazio!</Error>}
            <MaskedInput
              control={control}
              validation={{ required: true }}
              id="telefone"
              label="*Telefone / Login"
              placeholder="(00) 00000-0000"
              mask="(99) 9 9999-9999"
              type="tel"
              icon={<MdPhone size={18} color="#797979" />}
              defaultValue={user.telefone}
            />
            {errors.telefone && <Error>O campo telefone não pode ser vazio!</Error>}
            <TextInput
              register={register}
              id="senha"
              label="*Senha"
              placeholder="*********"
              type="password"
              icon={<MdLock size={18} color="#797979" />}
            />
            {errors.senha && <Error>{errors.senha?.message}</Error>}
            <TextInput
              register={register}
              validation={{
                required: {
                  value: true,
                  message: 'O campo email não pode ser vazio!',
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: 'Digite um endereço de email válido!',
                },
              }}
              id="email"
              label="Email"
              placeholder="@"
              type="email"
              icon={<MdMailOutline size={18} color="#797979" />}
              defaultValue={user.email}
            />
            {errors.email && <Error>{errors.email?.message}</Error>}
            <MaskedInput
              control={control}
              id="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              mask="999.999.999-99"
              icon={<MdAccountBox size={18} color="#797979" />}
              defaultValue={user.cpf}
            />
            {errors.cpf && <Error>{errors.cpf?.message}</Error>}
            <TextInput
              register={register}
              id="cadSenha"
              label="CAD-Senha"
              placeholder="*********"
              type="password"
              icon={<MdLock size={18} color="#797979" />}
            />
            <div style={{ display: 'flex' }}>
              <RadioInput
                register={register}
                title="*Cliente"
                group="cliente"
                options={[
                  { label: 'Sim', value: 'sim', checked: user.is_customer },
                  { label: 'Não', value: 'nao', checked: !user.is_customer }
                ]}
                style={{ flexGrow: '1' }}
              />
              <Code>
                <label>Código</label>
                <span>#05126875930</span>
              </Code>
            </div>
            <TextInput
                register={register}
                id="emailinss"
                label="Login INSS"
                placeholder="@"
                type="txt"
                icon={<MdMailOutline size={18} color="#797979" />}
                defaultValue={user?.email_inss}
            />
            {errors.emailinss && <Error>{errors.email?.emailinss}</Error>}
            <TextInput
              register={register}
              id="senhainss"
              label="Senha INSS"
              placeholder="*********"
              type="text"
              icon={<MdLock size={18} color="#797979" />}
              defaultValue={user?.password_inss}
            />
            {errors.senhainss && <Error>{errors.senhainss?.message}</Error>}
            <div className="button-edit">
              <Button text="Salvar Alterações" />
            </div>
          </form>
        </Card>
      </Container>
    </>
  );
}

export default EditProfile;