import styled from 'styled-components';

export const Container = styled.div`
  padding: 43px 18px;

  img{
    margin-bottom: 37px;
    height: 50px;
  }

  h1{
    color: white;
    font-size: 24px;
    margin-bottom: 14px;
  }

  .checkbox{
    display: flex;
    font-size: 11px;
    margin-top: 21px;
    margin-bottom: 20px;

    span{
      color: #797979;
    }

    a{
      color: #C59742;
      outline: none;
      text-decoration: none;
    }
  }

  .button-register{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 26px;
    gap: 16px;


    .link {
      font-size: 13px;
      line-height: 15px;
      color: #002854;
      text-decoration: none;
      margin-bottom: 17px;
    }
  }
`;

export const Error = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: #D61F2A;
`;

export const Icon = styled.div`
  text-align: center;
`;

export const TextModal = styled.div`
  text-align: center;
  width: 240px;

  span{
    font-weight: 700;
    width: 200px;
  }

`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;